interface RestMethodBadgeProps {
  method: string;
}

const methodColors: Record<string, string> = {
  GET: "bg-blue-500",
  POST: "bg-green-500",
  PUT: "bg-orange-500",
  DELETE: "bg-red-500",
  PATCH: "bg-cyan-500",
};

export default function RestMethodBadge({ method }: RestMethodBadgeProps) {
  // Get the appropriate background color based on the method or default to gray
  const color = methodColors[method.toUpperCase()] || "bg-gray-500";

  return (
    <span
      class={`text-sm sm:text-base text-white font-bold py-1 px-1.5 rounded-lg ${color} inline-block`}
      style="min-width: 4.6rem; text-align: center;" // Ensures minimum width and centered text
    >
      {method.toUpperCase()} {/* Display the HTTP method in uppercase */}
    </span>
  );
}
